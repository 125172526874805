@import "../../assets/scss/all/custom/variables.scss";

.head-search {
  max-width: 296px;

  button {
    align-items: center;
    background-color: $themecolor;
    border-radius: 0 20px 20px 0;
    color: $white;
    display: flex;
    font-size: 18px;
    height: 100%;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    transition: background-color ease 0.3s;
    width: 52px;

    i {
      margin-right: 0;
      padding-bottom: 5px;
    }

    &:hover {
      background-color: $dark-green;
    }
  }

  input[type="search"] {
    background-color: $white;
    border-radius: 30px !important;
    border: 1px solid $gray-400;
    font-size: $font-size-base;
    height: 41px;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 60px;

    &::placeholder {
      text-transform: capitalize;
    }
  }
}
