@import "../../assets/scss/all/custom/variables.scss";

.editable_cell {
  cursor: pointer;
  border: 1px solid transparent;
  padding: 0 5px;
  transition: all ease 0.3s;
  min-height: 25px;

  &:hover {
    border: 1px solid $gray-300;
  }
}

.btn {
  .anticon-setting, i {
    //font-size: 16px;
    //margin-right: 0.5rem;
  }
  @media (max-width: 858px) {
    .sm-hidden {
      display: none;
    }
    .anticon-setting, i {
      // margin-right: 0;
    }
  }
}

.ant-table-wrapper {
  .ant-table-selection-extra {
    padding-inline-start: 2px!important;
    top: 1px;

    .anticon {
      vertical-align: middle;
    }
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    display: none;
  }

  50% {
    opacity: 0.5;
    display: block;
  }

  100% {
    opacity: 1;
    display: block;
  }
}

.table-header {
  animation: fadeInOut 1s ease-in-out;
}
